<script setup lang="ts">
import {
  useAverageInitialRentChart,
  createDashboardPortfolioURI,
  DashboardQuery,
} from '@dashboard'

const { unitSize } = useDivisions()

/* === FETCH DATA === */
const companyId = getCurrentDivisionId()
const { data, isPending } = useApiDashboard(
  companyId,
  DashboardQuery.AVERAGE_INITIAL_RENT,
)
const isEmpty = computed(
  () => Object.keys(data.value?.results ?? {}).length === 0,
)
/* === FETCH DATA === */

const options = useAverageInitialRentChart(
  computed(() => data.value?.results ?? {}),
)
</script>

<template>
  <DashboardWidget
    header="Average Initial Rent"
    :subheader="`($/${unitSize?.text})`"
    class="pb-0"
    :loading="isPending"
    :is-empty="isEmpty"
  >
    <template #additional>
      <DashboardMissingData
        v-if="data?.missingData"
        v-bind="data.missingData"
      />
    </template>

    <highchart ref="chartRef" :options="options" class="h-64 w-full" />
  </DashboardWidget>
</template>
