import type {
  Options as HighchartsOptions,
  SeriesSplineOptions,
} from 'highcharts'
import type { Ref } from 'vue'
import {
  getYearsInRange,
  createDashboardReportURI,
  convertDashboardAverageinitialRentResultToChart,
} from '../utils'
import type { IDashboardResultAverageInitialRent } from '~/composables/useAPI/useApiDashboard'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'
import {
  getCurrentDivisionId,
  useDivisions,
} from '@shared/composables/useDivisions'

export const useAverageInitialRentChart = (
  result: Ref<IDashboardResultAverageInitialRent>,
) => {
  const router = useRouter()
  const companyId = getCurrentDivisionId()
  const { currentDivision, unitSize } = useDivisions()

  /* === RANGE === */
  const endYear = new Date().getFullYear()
  const startYear = endYear - 10
  /* === RANGE === */

  function createCurrency(amount: number): string {
    //TODO: fix type error
    const localization = currentDivision.value.localization

    const locale = localization.locale ?? 'en-AU'
    const currency = localization.currency?.id || 'AUD'
    const currencyDisplay = 'narrowSymbol'

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
    }).format(amount)
  }

  function tooltip(data: any) {
    const year = result.value[data.key]
    const location = abbreviationToFullState(data.series.name)
    const value = createCurrency(data.y)

    let totalAverage: string | number = 0
    for (const key in year) {
      if (year[key] && year[key].average !== undefined) {
        totalAverage += year[key].average
      }
    }

    totalAverage = createCurrency(totalAverage)

    return /* html */ `
      <b>Average initial rent in ${data.key}</b>
      <br/>${location}: <b>${value}</b>
    `
  }
  const series = computed(() => {
    return convertDashboardAverageinitialRentResultToChart(
      result.value,
      startYear,
      endYear,
    ).map<SeriesSplineOptions>((item) => {
      return {
        ...item,
        name: fullStateToAbbreviation(item.name),
        type: 'spline',
        borderWidth: 0,
        borderRadius: 6,
        events: {
          legendItemClick(e) {
            // const options = e.target.options
            // if ('link' in options) {
            //   redirectToReport(
            //     options.link as ISearchPropertyLeasePortfolioRequest,
            //   )
            // }
            // return false
          },
        },
      }
    })
  })

  return computed<HighchartsOptions>(
    () =>
      ({
        chart: {
          type: 'spline',
          backgroundColor: 'transparent',
          events: {
            load() {
              const xAxis = this.xAxis[0]
              const yAxis = this.yAxis[0]

              // Add label at the bottom-left corner (near the X and Y axis intersection)
              this.renderer
                .text(
                  'CY:',
                  xAxis.left - 25,
                  yAxis.toPixels(0) + this.spacing[2] + 12,
                )
                .attr({
                  zIndex: 5,
                })
                .css({
                  color: '#BFBFBF',
                  fontSize: '12px',
                })
                .add()
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          crosshair: true,
          categories: getYearsInRange(result.value, startYear, endYear),
          labels: {
            style: {
              color: '#BFBFBF',
            },
          },
        },
        yAxis: {
          // type: 'logarithmic',
          allowDecimals: false,
          min: 0,
          gridLineColor: '#444',
          // tickInterval: 40,
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#BFBFBF',
            },
          },
          title: {
            text: '',
            style: {
              color: '#BFBFBF',
            },
          },
        },
        // tooltip: {
        //   format: '<b>{key}</b><br/>{series.name}: {y}<br/>',
        // },

        tooltip: {
          formatter: function () {
            return tooltip(this)
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            connectNulls: true,
            // marker: false,
            lineWidth: 2,
            point: {
              events: {
                click() {
                  if ('link' in this) {
                    redirectToReport(
                      this.link as ISearchPropertyLeasePortfolioRequest,
                      //TODO: add templateId this.templateId as ...
                    )
                    useAnalytics({
                      name: 'Chart click',
                      showDivision: true,
                      data: {
                        Origin: `Average initial rent ($/${unitSize.value?.text})`,
                      },
                    })
                  }
                },
              },
            },
          },
        },
        legend: {
          itemHoverStyle: {
            color: '#D8FC59',
          },
          itemStyle: {
            color: 'white',
          },
        },

        series: series.value.map<SeriesSplineOptions>((item) => {
          return {
            ...item,
            name: fullStateToAbbreviation(item.name),
            type: 'spline',
            borderWidth: 0,
            borderRadius: 6,
            events: {
              legendItemClick(e) {
                // const options = e.target.options
                // if ('link' in options) {
                //   redirectToReport(
                //     options.link as ISearchPropertyLeasePortfolioRequest,
                //   )
                // }
                // return false
              },
            },
          }
        }),
      }) satisfies HighchartsOptions,
  )

  function redirectToReport(
    link: ISearchPropertyLeasePortfolioRequest | undefined,
    templateId: string,
  ) {
    const path = createDashboardReportURI(companyId, link, templateId)
    if (path) {
      router.push(path)
    }
  }
}
